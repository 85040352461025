// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/dialog-footer_1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/dialog-footer_2.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-dialog__footer{color:#fff;background-color:#6732ff;background-image:linear-gradient(45deg,#6732ff,#ff6cb3);display:flex;flex-direction:column;justify-content:center;border-radius:0;align-items:center;padding:32px;position:relative}@media screen and (max-width:47.9375em){.contact-dialog__footer{text-align:center}}@media screen and (min-width:48em){.contact-dialog__footer{border-radius:0 0 32px 32px}}.contact-dialog__footer:after,.contact-dialog__footer:before{content:\"\";width:100%;height:100%;position:absolute;left:0;top:0}@media screen and (min-width:48em){.contact-dialog__footer:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position-x:10%;background-position-y:32px}.contact-dialog__footer:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-position:top -32px right -32px}}.contact-dialog__footer__email,.contact-dialog__footer__title{font-size:1.5rem;line-height:2.5rem}@media screen and (max-width:47.9375em){.contact-dialog__footer__email,.contact-dialog__footer__title{font-size:1.125rem;line-height:2rem}}.contact-dialog__footer__title{font-weight:300}.contact-dialog__footer__email{font-weight:600}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
