//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppButton from '@/components/AppButton';
import axios from 'axios';

export default {
  name: 'ContactForm',
  components: { AppButton },
  data() {
    return {
      submitting: false,
      success: false,
      errors: [],
      formData: {
        name: '',
        email: '',
        message: '',
      },
      whatsappUrl: process.env.WHATSAPP_URL,
    };
  },
  computed: {
    formName() {
      return `contact-form-${this.currentlocale}`;
    },
    currentlocale() {
      return this.$i18n?.locale.toLowerCase() || 'nl';
    },
    whatsappMessage() {
      return encodeURIComponent(this.formData?.message);
    },
  },
  methods: {
    focusFirst() {
      this.$refs.nameInput.focus();
    },
    checkForm() {
      if (this.formData.name && this.formData.email && this.formData.message) {
        return true;
      }

      this.errors = [];

      if (!this.formData.name) this.errors.push('Name required');
      if (!this.formData.email) this.errors.push('Email required');
      if (!this.formData.message) this.errors.push('Message required');
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    },
    handleSubmit() {
      this.submitting = true;

      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      };

      axios
        .post(
          '/',
          this.encode({
            'form-name': this.formName,
            language: this.currentlocale,
            ...this.formData,
          }),
          axiosConfig,
        )
        .then((response) => {
          if (this.$gtm) {
            this.$gtm.push({
              event: 'generate_lead',
            });
          }
          this.$emit('success');
          if (response) {
            this.success = true;
          }
        })
        .catch((err) => {
          this.errors.push(err);
          this.success = false;

          this.formData = {
            name: '',
            email: '',
            message: '',
          };
        })
        .finally(() => (this.submitting = false));
    },
  },
};
