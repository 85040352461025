//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '@/assets/svg/close.svg';
import ContactForm from './ContactForm';
import ContactDialogFooter from './ContactDialogFooter';
import FormSuccess from './FormSuccess';

export default {
  name: 'ContactDialog',
  components: {
    ContactForm,
    ContactDialogFooter,
    CloseIcon,
    FormSuccess,
  },

  data() {
    return {
      success: false,
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isShowing ? 'dialog-open' : null,
      },
    };
  },
  computed: {
    isShowingContactDialog() {
      return this.$store.state.isShowingContactForm;
    },
  },
  watch: {
    isShowingContactDialog(val) {
      if (val) this.focusFirst();
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closeDialogWithEscape);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.closeDialogWithEscape);
  },
  methods: {
    closeDialogWithEscape(e) {
      if (e.key === 'Escape' && this.isShowingContactDialog) {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$store.dispatch('hideContactForm');
    },
    openDialog() {},
    showSuccess() {
      this.success = true;
    },
    focusFirst() {
      this.$refs.contactForm.focusFirst();
    },
  },
};
